
import Vue from 'vue'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VueNoty from 'vuejs-noty'
import 'vuejs-noty/dist/vuejs-noty.css'

Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(Vuetify)
Vue.use(VueNoty, {
    timeout: 4000,
    progressBar: true,
    layout: 'bottomRight'
})

import SignIn from '../components/devise/sign_in.vue'
import 'vuetify/dist/vuetify.min.css'
import pl from 'vuetify/src/locale/pl.ts'

document.addEventListener("turbolinks:load", function() {
    Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const devise_vue = document.querySelector("#devise_vue");
    if (devise_vue) {
        window.options = JSON.parse(devise_vue.dataset.options);
        window.config = JSON.parse(devise_vue.dataset.config);
        const opts = {
            lang: {
                locales: { pl },
                current: 'pl',
            },
        }
        new Vue({
            el: devise_vue,
            vuetify: new Vuetify(opts),
            render: h => h(SignIn),
            data: {
                options: devise_vue.dataset.options,
                config: devise_vue.dataset.config,
            },
        })
    }

});
