<template>
    <div>
        <v-app id="inspire">
            <v-parallax
                    dark
                    src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
                    height="1200"
            >
                <v-row
                        align="center"
                        justify="center"
                >
                    <v-container v-if="mode == 'sign_in'">
                        <v-col>
                            <h1 class="display-2 font-weight-thin mb-4">{{ config.title }}</h1>
                            <h4 class="subheading">{{ config.i18n.failure.unauthenticated }}</h4>
                        </v-col>
                        <v-col>
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col
                                                cols="12"
                                                md="4"
                                        >
                                            <v-text-field
                                                    style="background-color: none"
                                                    dark
                                                    v-model="login"
                                                    :label="config.i18n.sessions.new.email"
                                                    required
                                                    prepend-icon="mdi-account"
                                            ></v-text-field>

                                            <v-text-field
                                                    dark
                                                    v-model="password"
                                                    :label="config.i18n.sessions.new.password"
                                                    required
                                                    prepend-icon="mdi-lock"
                                                    :append-icon="show1 ? 'visibility' : 'visibility_off'"
                                                    @click:append="show1 = !show1"
                                                    :type="show1 ? 'text' : 'password'"
                                            ></v-text-field>

                                            <v-btn class="success" @click="signIn()">{{ config.i18n.main_titles.sign_in }}</v-btn>
                                            <p class="mt-5" @click="mode = 'new_password'">{{ config.i18n.main_titles.forgot_password }}</p>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-col>
                    </v-container>

                    <v-container v-else-if="mode == 'new_password'">
                        <v-col>
                            <h1 class="display-2 font-weight-thin mb-4">{{ config.title }}</h1>
                            <h4 class="subheading">{{ config.i18n.pages.titles.passwords.new }}</h4>
                            <br/>
                            <span class="muted" v-html="config.i18n.passwords.new.description"></span>
                        </v-col>
                        <v-col>
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col
                                                cols="12"
                                                md="4"
                                        >
                                            <v-text-field
                                                    dark
                                                    v-model="login"
                                                    :label="config.i18n.passwords.new.email"
                                                    required
                                                    prepend-icon="mdi-account"
                                            ></v-text-field>

                                            <v-btn class="success" @click="newPassword()">{{ config.i18n.passwords.new.send_password }}</v-btn>
                                            <p class="mt-5" @click="mode = 'sign_in'">{{ config.i18n.pages.titles.back }}</p>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-col>
                    </v-container>

                    <v-container v-else-if="mode == 'edit_password'">
                        <v-col>
                            <h1 class="display-2 font-weight-thin mb-4">{{ config.title }}</h1>
                            <h4 class="subheading">{{ config.i18n.pages.titles.passwords.create }}</h4>
                        </v-col>
                        <v-col>
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col
                                                cols="12"
                                                md="4"
                                        >
                                            <v-text-field
                                                    dark
                                                    v-model="password"
                                                    :label="config.i18n.sessions.new.password"
                                                    required
                                                    prepend-icon="mdi-lock"
                                                    :append-icon="show1 ? 'visibility' : 'visibility_off'"
                                                    @click:append="show1 = !show1"
                                                    :type="show1 ? 'text' : 'password'"
                                            ></v-text-field>

                                            <v-text-field
                                                    dark

                                                    v-model="password2"
                                                    :label="config.i18n.sessions.new.password_confirmation"
                                                    required
                                                    prepend-icon="mdi-lock"
                                                    :append-icon="show2 ? 'visibility' : 'visibility_off'"
                                                    @click:append="show2 = !show2"
                                                    :type="show2 ? 'text' : 'password'"
                                            ></v-text-field>

                                            <v-btn class="success" @click="editPassword()">OK</v-btn>
                                            <p class="mt-5" @click="mode = 'sign_in'">{{ config.i18n.pages.titles.back }}</p>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-col>
                    </v-container>
                </v-row>

            </v-parallax>


            <v-footer>
                <v-card
                        flat
                        tile
                        width="100%"
                        class="text-center pt-4"
                        style="margin-top: -120px; height:120px"
                >

                    <v-row align="center" justify="center">
                        <v-img src="http://kamee-software.com/images/ruby-poland.png" max-width="150"></v-img>
                    </v-row>
                    <v-card-text >
                        &copy;{{ new Date().getFullYear() }} — <a href="https://kamee-software.com" target="_blank"><strong>kamee-software.com</strong></a>
                    </v-card-text>
                </v-card>
            </v-footer>

        </v-app>
    </div>

</template>

<script>

    // import { i18n } from 'components/lang'

    export default {

        components: { },

        props: [],

        data: function() {
            var options = window.options
            var config = window.config
            return {
                show1: false,
                show2: false,
                login: '',
                password: '',
                password2: '',
                mode: options['reset_password_token'] ? 'edit_password' : 'sign_in',
                options: options,
                config: config
            }
        },

        methods: {
            signIn: function() {
                this.$http.post(this.options['root_url'] + 'users/sign_in', {user: {email: this.login, password: this.password}}).then(response => {
                    location.reload()
                }).catch(response => {
                    for (let i in response.body)
                        this.$noty.error(response.body[i])
                })
            },

            newPassword: function() {
                this.$http.post(this.options['root_url'] + 'users/password', {user: {email: this.login}}).then(response => {
                    location.reload()
                }).catch(response => {
                    for (let i in response.body)
                        this.$noty.error(response.body[i])
                })
            },

            editPassword: function() {
                this.$http.put(this.options['root_url'] + 'users/password', {user: {password: this.password, password_confirmation: this.password2, reset_password_token: this.options['reset_password_token']}}).then(response => {
                    location.reload()
                }).catch(response => {
                    for (let i in response.body)
                        this.$noty.error(response.body[i])
                })
            }
        }

    }
</script>

<style scoped>


</style>
